import React from 'react';
import styles from "./layers.module.css";
import inputs from "../UI/input.module.css";
import Grid from "../UI/Grid";
import Btn from "../UI/Btn";
import {Link} from "react-router-dom";
import UploadFile from "./UploadFile";

const UserTools = (props) => {

    const userAuthHandler = (e) => {
        e.preventDefault();

        if (Object.keys(props.userStorage).length === 0) {

            const form = e.target;
            const formData = new FormData(form);
            props.authToServer(formData);
        }
    };

    return (
        <React.Fragment>
            <div className={styles.leftBar}>
                <div className={styles.toolsSection}>
                    <h2>{props.isLogin ? `Пользователь: ${props.userStorage.name}` : 'Авторизоваться'}</h2>

                    {props.serverMessage && <div className={styles.warning}>{props.serverMessage}</div>}

                    {props.isLogin ?
                        <Btn onClick={props.logoutHandler} center={true}>Выйти</Btn>
                        :
                        <form onSubmit={userAuthHandler}>
                            <Grid cols={2}>
                                <label>Логин</label>
                                <input className={inputs.input} name={'login'} />
                                <label>Пароль</label>
                                <input className={inputs.input} name={'password'} />
                            </Grid>
                            <Btn type="submit">Авторизоваться</Btn>
                        </form>
                    }
                </div>
            </div>
            <div className={`p-3`}>
                <div className={styles.toolsSection}>
                    <UploadFile
                        saveData={props.saveData}
                        filesList={props.filesList}
                        getDataHandler={props.getDataHandler}
                    />
                </div>
                {(props.filesList && props.filesList.length > 0) && <React.Fragment>
                    <h2>Список загруженных файлов</h2>
                    <ul>
                    {props.filesList.map((file,id) => <Btn key={id}>
                        <Link to={`./sort/?work=${file.replace('.json','')}`}>
                            {file.replace('.json','')}
                        </Link>
                    </Btn>)}
                </ul>
                </React.Fragment>}
            </div>
        </React.Fragment>
    );
};

export default UserTools;
