import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from "react-router-dom";
import LayerTools from "./LayerTools";
import PageCount from "../UI/Modules/PageCount";
import tables from "../UI/Tables/table.module.css";
import { reactLocalStorage } from "reactjs-localstorage";
import Btn from "../UI/Btn";
import styles from "./layers.module.css";
import Popup from "../UI/Popup";

const GroupRequests = (props) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const fileName = searchParams.get('work');

    const [addRequests, setAddRequests] = useState([]);
    const [selectCount, setSelectCount] = useState();
    const [printRequest, setPrintRequest] = useState([]);
    const [selectedGroupWords, setSelectedGroupWords] = useState([]);
    const [selectedGroupRequests, setSelectedGroupRequests] = useState([]);
    const [groups,setGroups] = useState({});
    const [groupExpanded, setGroupExpanded] = useState()
    const [popupShow, setPopupShow] = useState(false);

    const sliceRequests = useCallback(() => {
        let startIndex, endIndex;
        if (typeof selectCount === 'number') {
            endIndex = selectCount;
            startIndex = endIndex - 100;
        } else {
            startIndex = 0;
            endIndex = 100;
        }
        const requests = addRequests.slice(startIndex, endIndex);
        setPrintRequest(requests);
    }, [addRequests, selectCount]);
    const storedSettings = reactLocalStorage.getObject(fileName);
    const addMasks = storedSettings.addMasks || [];
    const updateSelectedWords = (keys, method) => {
        if (method === 'add') setSelectedGroupWords(prevSelectedWords => [...new Set([...prevSelectedWords, ...keys])]);
        else if (method === 'exclude') setSelectedGroupWords(selectedGroupWords.filter(w => !keys.includes(w)));
    };
    const groupSelectedRequests = async (e) => {

        e.preventDefault();

        const name = e.target.name.value;

        const updateRequests = [...props.requests].sort((a, b) => a.id - b.id).map(r => {
            if (selectedGroupRequests.includes(r.id)) {
                return { ...r, group: name.trim(' ') };
            }
            return r;
        });

        await props.saveDataHandler(updateRequests, fileName);

        setPopupShow(false);
    }
    const removeRequest = async (id) => {

        const updateRequests = [...props.requests].map(r => {
            if (r.id === id) {

                r.status = 'print';

                return r;
            }
            else return r;
        });

        await props.saveDataHandler(updateRequests, fileName);
    }
    const addGroups = async (requests) => {
        await props.saveDataHandler(requests, fileName);

        setPopupShow(false);
    }
    const removeRequestIntoGroup = async (id) => {

        const updateRequests = props.requests.map(r => {

            if (r.id === id) delete r.group;

            return r;
        });

        console.log(`\n updateRequests`, updateRequests);

        await props.saveDataHandler(updateRequests, fileName);
    }
    const addToGroupHandler = (id) => {

        const addToGroup = async (groupName) => {

            console.log(`\n addToGroup`, groupName);

            const updateRequests = props.requests.map(r => {

                if (r.id === id) r.group = groupName;

                return r;
            });

            await props.saveDataHandler(updateRequests, fileName);
            setPopupShow(null);
        }

        // console.log(`\n addToGroupHandler`, id);

        setPopupShow(<Popup
            title={`Добавить к группе`}
            groups={groups}
            onSubmit={addToGroup}
            onCloseModal={() => setPopupShow(false)}
        />);
    }

    useEffect(() => {
        if (props.requests.length === 0) props.getDataHandler(fileName);
        else {

            setAddRequests(props.requests.filter(r => (r.status === 'add' && !r.group)));

            const groupsRequests = {}
            props.requests.filter(r => r.group).forEach(r => {
                if (!groupsRequests[r.group]) groupsRequests[r.group] = [];
                groupsRequests[r.group].push(r.id);
            });
            setGroups(groupsRequests);
        }
    }, [props, fileName]);
    useEffect(() => {
        sliceRequests();
    }, [sliceRequests])
    useEffect(() => {
        const updateSelectedWords = addRequests.filter(r => selectedGroupWords.every(word => r.request.includes(word))).map(r => r.id);

        if (selectedGroupWords.length > 0) {
            setSelectedGroupRequests(updateSelectedWords);
        } else {
            setSelectedGroupRequests([]);
        }
    }, [selectedGroupWords, addRequests]);
    useEffect(() => {
        const sortedRequests = [...addRequests].sort((a, b) => {
            const indexA = selectedGroupRequests.indexOf(a.id);
            const indexB = selectedGroupRequests.indexOf(b.id);
            return indexB - indexA;
        });
        setPrintRequest(sortedRequests);
    }, [selectedGroupRequests, addRequests]);

    // console.log(`\n groups`, groups);

    return (
        <React.Fragment>
            {popupShow}
            <LayerTools
                requests={props.requests ? props.requests : []}
                workMethod={'group'}
                fileName={fileName}
                selectedGroupWords={selectedGroupWords}
                updateSelectedWords={updateSelectedWords}
                selectedGroupRequests={selectedGroupRequests}
                groupSelectedRequests={groupSelectedRequests}
                setPopupShow={setPopupShow}
                minusRequest={props.minusRequest}
                groups={groups}
                addGroups={addGroups}
            />
            <div className={`flex flex-col grow`}>
                <PageCount data={addRequests} selectCount={selectCount} setSelectCount={setSelectCount} />
                <div className={`${tables.scroll} flex-1`}>
                    <table className={tables.table}>
                        <thead>
                        <tr>
                            <th colSpan={2}>БЧ</th>
                            <th>Запрос</th>
                        </tr>
                        </thead>
                        <tbody>{printRequest.map(r => {
                            const highlightWords = (text) => {
                                addMasks.forEach(mask => {
                                    const regex = new RegExp(mask, 'gi');
                                    text = text.replace(regex, '<var class="bg-lime-500/60">$&</var>');
                                });

                                if (selectedGroupRequests.length > 0) selectedGroupWords.forEach(word => {
                                    const wordRegex = new RegExp(word, 'gi');
                                    text = text.replace(wordRegex, '<var class="bg-amber-300/30">$&</var>');
                                });

                                return text;
                            };

                            // console.log(`\n `, r);

                            return (<tr key={r.request} className={selectedGroupRequests.includes(r.id) ? tables.selected : ''}>
                                <td className={`w-5`}>
                                    <div className={`flex w-max`}>
                                        <Btn method={`delete`} onClick={() => removeRequest(r.id)}/>
                                        <Btn method={`addGroup`} onClick={() => addToGroupHandler(r.id)}/>
                                    </div>
                                </td>
                                <td className={`text-center`}>{r.baseFrequency}</td>
                                <td>
                                    <div onMouseUp={() => {
                                        const selectedText = window.getSelection()?.toString()?.trim().split(' ') || '';

                                        if (selectedText.length > 0) selectedText.forEach(w => {
                                            if (w.length > 2) updateSelectedWords([w], 'add');
                                        });
                                    }}>
                                        <span dangerouslySetInnerHTML={{ __html: highlightWords(r.request) }}></span>
                                    </div>
                                </td>
                            </tr>);
                        })}</tbody>
                    </table>
                </div>
            </div>
            <div className={`flex flex-col ml-2 h-screen overflow-auto`}>
                <h2>Группы запросов</h2>
                {Object.keys(groups).length > 0 && Object.keys(groups).sort().map(g => <React.Fragment key={g}>
                    <Btn active={groupExpanded === g} onClick={() => {

                        if (groupExpanded !== g) setGroupExpanded(g);
                        else setGroupExpanded(null);

                    }}>{g} - ({groups[g].length})</Btn>
                    {groupExpanded === g && <div className={styles.additional}>{groups[g].map(id => {

                        const request = props.requests.find(r => r.id === id);

                        return (<div key={id} className={`flex my-1.5 pr-2`}>
                            <Btn method={`delete`} title={`Исключить запрос из группы`} onClick={() => removeRequestIntoGroup(id)} />
                            <div className={`mx-1 border border-mainText/50 rounded-md p-0.5`}>{request.baseFrequency}</div>
                            <div>{request.request}</div>
                        </div>)
                    })}</div>}
                </React.Fragment>)}
            </div>
        </React.Fragment>
    );
};

export default GroupRequests;