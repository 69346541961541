import React, {useEffect, useState} from 'react';
import LayerTools from "./LayerTools";
import PageCount from "../UI/Modules/PageCount";
import tables from "../UI/Tables/table.module.css";
import PrintCharts from "./PrintCharts";
import Btn from "../UI/Btn";
import {Link, useLocation  } from "react-router-dom";
import {ReactComponent as Google} from "../UI/Icons/search/google.svg";
import {ReactComponent as Yandex} from "../UI/Icons/search/yandex.svg";
import {ReactComponent as YandexDirect} from "../UI/Icons/search/yandex_direct.svg";
import {ReactComponent as YandexImages} from "../UI/Icons/search/yandex_images.svg";
import {reactLocalStorage} from "reactjs-localstorage";

const SortRequests = (props) => {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const fileName = searchParams.get('work');
    if (props.requests.length === 0) props.getDataHandler(fileName);

    const storedSettings = reactLocalStorage.getObject(fileName);
    const [addMasks, setAddMasks] = useState(storedSettings.addMasks || []);
    const [userMasks, setUserMasks] = useState(storedSettings.userMasks || []);
    const [method, setMethod] = useState(storedSettings.method || 1);
    const [selectedWords, setSelectedWords] = useState(storedSettings.selectedWords || []);

    const [selectCount,setSelectCount] = useState();
    const [printRequest,setPrintRequest] = useState();
    useEffect(() => {

        let startIndex, endIndex;
        if (typeof selectCount === 'number') {
            endIndex = selectCount;
            startIndex = endIndex - 100;
        } else {
            startIndex = 0;
            endIndex = 100;
        }
        const requests = props.requests.filter(r => r.status === 'print').slice(startIndex, endIndex);

        setPrintRequest(requests);
    }, [props.requests, selectCount, method]);

    const [selectedRequests, setSelectedRequests] = useState();
    const [selectCountWords, setSelectCountWords] = useState(40);
    const [selectCountRequests, setSelectCountRequests] = useState(100);
    const [upRequests,setUpRequests] = useState([]);
    useEffect(() => {

        const requests = {};
        props.requests.filter(r => r.status === 'print').forEach(r => {

            // console.log(`\n selectedWords`, selectedWords);

            selectedWords.forEach(sw => {

                if (r.request.includes(sw)) {

                    const word = r.request.split(' ').filter(w => w.includes(sw))[0];

                    console.log(`\n `, word);

                    if (!requests[r.id]) requests[r.id] = [word];
                    else requests[r.id].push(word);
                }
            })
        });

        setSelectCountWords(40);
        setSelectCountRequests(100);
        setSelectedRequests(requests);
    },[selectedWords,props.requests]);
    const selectedWordList = selectedRequests ? [...new Set([].concat(...Object.values(selectedRequests)))].sort() : [];
    const upWord = (word) => {
        const updateUpRequests = Object.keys(selectedRequests)
            .filter(id => selectedRequests[id]?.includes(word))
            .map(Number);

        setUpRequests(updateUpRequests);
        setSelectCountRequests(100);
    };
    const wordCount = selectedRequests ? Object.values(selectedRequests).flat().reduce((count, word) => {
            count[word] = (count[word] || 0) + 1;
            return count;
        }, {}) : {};
    const selectedWordListWithCount = selectedWordList.map(word => ({word,count: wordCount[word] || 0,}));
    const deleteSelectedWord = (word) => {

        const filteredRequests = Object.fromEntries(
            Object.entries(selectedRequests).filter(([id, words]) => !words.includes(word))
        );
        setSelectedRequests(filteredRequests);
    }
    const reselectRequests = () => {

        setSelectedWords(userMasks);
        setUserMasks([]);
    }
    const statusRequestsHandler = async (type) => {
        let updateMinusRequests = { ...props.minusRequest };

        if (type === 'minus') {
            Object.keys(selectedRequests).forEach(id => {
                if (selectedRequests[id]) {
                    updateMinusRequests[id] = selectedRequests[id];
                }
            });
        }

        props.setMinusRequest(updateMinusRequests);

        const updateRequests = props.requests.map(r => {
            if (Object.keys(selectedRequests).includes(r.id.toString())) {
                return { ...r, status: type };
            }
            return r;
        });

        props.setRequests(updateRequests);

        await props.saveDataHandler(updateRequests, fileName, updateMinusRequests);
    };

    const [popupShow, setPopupShow] = useState(false);

    useEffect(() => {

        reactLocalStorage.setObject(fileName, {
            addMasks,
            method,
            userMasks,
            selectedWords,
        });
    },[addMasks,method,userMasks,selectedWords,fileName]);

    // console.log(`\n props.requests`, props.requests);

    return (<React.Fragment>
        {popupShow}
        <LayerTools
            requests={props.requests}
            workMethod={'sort'}
            setMethod={setMethod}
            selectedRequests={selectedRequests}
            userMasks={userMasks}
            setUserMasks={setUserMasks}
            reselectRequests={reselectRequests}
            minusRequest={props.minusRequest}
            setPopupShow={setPopupShow}
            deleteMinusRequestHandler={props.deleteMinusRequestHandler}
            addMasks={addMasks}
            setAddMasks={setAddMasks}
            updateIgnoreRequestHandler={props.updateIgnoreRequestHandler}
            resetDataHandler={props.resetDataHandler}
            fileName={fileName}
        />
        {method === 1 ?
            <div className={`flex flex-col`}>
                {props.requests.filter(r => r.status === 'print').length > 0 ? <React.Fragment>
                        <div className={`max-h-24 overflow-auto`}>
                            <PageCount
                                data={props.requests.filter(r => r.status === 'print')}
                                selectCount={selectCount}
                                setSelectCount={setSelectCount}
                            />
                        </div>
                        <div className={`${tables.scroll} flex-1`}>
                            <table className={tables.table}>
                                <thead>
                                <tr>
                                    <th>БЧ</th>
                                    <th>Запрос</th>
                                </tr>
                                </thead>
                                <tbody>
                                {printRequest && printRequest.map(r => {

                                    const highlightWords = (text) => {

                                        addMasks.forEach(mask => {
                                            const regex = new RegExp(mask, 'gi');
                                            text = text.replace(regex, '<var class="bg-lime-500/60">$&</var>');
                                        });

                                        return text;
                                    };

                                    return (
                                        <tr key={r.request} className={r.selected ? tables.selected : ''}>
                                            <td className={`text-center`}>{r.baseFrequency}</td>
                                            <td>
                                                <div onMouseUp={() => {
                                                    const selectedText = window.getSelection()?.toString()?.trim() || '';
                                                    const selectedWords = [];

                                                    if (selectedText && selectedText.length > 1) {

                                                        selectedText.split(' ').forEach((word) => {
                                                            if (word.length > 2) selectedWords.push(word);
                                                        });
                                                    }

                                                    if (selectedWords.length > 0) {
                                                        setSelectedWords(selectedWords);
                                                    }
                                                }}>
                                                    <span dangerouslySetInnerHTML={{ __html: highlightWords(r.request) }}></span>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                        </div>
                    </React.Fragment>
                    :
                    <h1 className={`m-2`}>Все запросы отработаны</h1>
                }
            </div>
            : (method === 2 && props.requests.length > 0) &&
            <PrintCharts
                requests={props.requests.filter(r => r.status === 'print')}
                setSelectedWords={setSelectedWords}
                selectedWordListWithCount={selectedWordListWithCount}
            />
        }
        {(selectedRequests && Object.keys(selectedRequests).length > 0) &&
            <div className={`h-screen px-3 py-2 flex flex-col`}>
                <div className={`flex w-max mx-auto`}>
                    <Btn
                        method={`refresh`}
                        onClick={() => setSelectedWords([])}
                    />
                    <Btn
                        method={`add`}
                        onClick={() => statusRequestsHandler('add')}
                    >
                        В работу
                    </Btn>
                    <Btn
                        method={`cancel`}
                        onClick={() => statusRequestsHandler('minus')}
                    >
                        В исключения
                    </Btn>
                    <Btn
                        method={`ignore`}
                        onClick={() => statusRequestsHandler('ignore')}
                    >
                        Игнорировать
                    </Btn>
                </div>
                <PageCount step={40} data={selectedWordList} selectCount={selectCountWords} setSelectCount={setSelectCountWords} />
                <div className={`${tables.scroll} flex-none max-h-[30vh] mb-5`}>
                    <table className={tables.table}>
                        <thead>
                        <tr>
                            <th colSpan={2}>Подобранные слова</th>
                        </tr>
                        </thead>
                        <tbody>
                        {selectedWordListWithCount.slice(selectCountWords -40, selectCountWords).map(({ word, count }) => (
                            <tr key={word}>
                                <td className={`w-0`}>
                                    <div className={`flex`}>
                                        <Btn method={`delete`} onClick={() => deleteSelectedWord(word)} />
                                        <Btn method={`upWord`} onClick={() => upWord(word)} />
                                    </div>
                                </td>
                                <td>{word} - ({count})</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                <PageCount data={Object.keys(selectedRequests)} selectCount={selectCountRequests} setSelectCount={setSelectCountRequests} />
                <div className={`${tables.scroll} flex-grow`}>
                    <table className={tables.table}>
                        <thead>
                        <tr>
                            <th colSpan={2}>Подобранные запросы</th>
                        </tr>
                        </thead>
                        <tbody>
                        {selectedRequests &&
                            [...Object.keys(selectedRequests)]
                                .sort((a, b) => {
                                    const inUpRequestsA = upRequests.includes(+a);
                                    const inUpRequestsB = upRequests.includes(+b);

                                    if (inUpRequestsA && !inUpRequestsB) {
                                        return -1;
                                    } else if (!inUpRequestsA && inUpRequestsB) {
                                        return 1;
                                    } else {
                                        return a - b;
                                    }
                                })
                                .slice(selectCountRequests - 100, selectCountRequests)
                                .map(id => {
                                    const request = props.requests.find(r => r.id === +id)?.request;

                                    const highlightWords = (text) => {
                                        selectedRequests[id].forEach(word => {
                                            const regex = new RegExp(word, 'gi');
                                            text = text.replace(regex, '<var class="bg-yellow-500/30">$&</var>');
                                        });

                                        addMasks.forEach(mask => {
                                            const regex = new RegExp(mask, 'gi');
                                            text = text.replace(regex, '<var class="bg-lime-500/60">$&</var>');
                                        });

                                        userMasks.forEach(mask => {
                                            const regex = new RegExp(mask, 'gi');
                                            text = text.replace(regex, '<var class="bg-sky-500/60">$&</var>');
                                        });

                                        return text;
                                    };

                                    const isHighlighted = upRequests.includes(+id);

                                    return (
                                        <tr key={id} className={isHighlighted ? tables.upRequest : ""}>
                                            <td className={`w-0`}>
                                                <div className={`w-max flex`}>
                                                    <Btn><Link to={`https://www.google.com/search?q=${request}`} target="_blank"><Google className={`w-4`} /></Link></Btn>
                                                    <Btn><Link to={`https://yandex.ru/search/?text=${request}`} target="_blank"><Yandex className={`w-4`} /></Link></Btn>
                                                    <Btn><Link to={`https://yandex.ru/search/ads?text=${request}`} target="_blank"><YandexDirect className={`w-4`} /></Link></Btn>
                                                    <Btn><Link to={`https://yandex.ru/images/search?text=${request}`} target="_blank"><YandexImages className={`w-4`} /></Link></Btn>
                                                </div>
                                            </td>
                                            <td
                                                className={`whitespace-nowrap`}
                                                dangerouslySetInnerHTML={{ __html: highlightWords(request) }}
                                                onMouseUp={() => {
                                                    const selectedText = window.getSelection()?.toString()?.trim() || '';
                                                    console.log(`\n `, selectedText);

                                                    if (selectedText && selectedText.length > 1) {
                                                        const words = selectedText.split(/\s+/);
                                                        const uniqueWords = [...new Set(words)];

                                                        setUserMasks(prevUserMasks => [...new Set([...prevUserMasks, ...uniqueWords])]);
                                                    }
                                                }}
                                            />
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
            </div>
        }
    </React.Fragment>);
}

export default SortRequests;