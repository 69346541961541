import './App.css';
import React, {Suspense, useCallback, useEffect, useState} from 'react';
import {Route, Routes, BrowserRouter} from 'react-router-dom';
import UserTools from "./components/modules/UserTools";
import SortRequests from "./components/modules/SortRequests";
import axios from "axios";
import {reactLocalStorage} from 'reactjs-localstorage';
import GroupRequests from "./components/modules/GroupRequests";
import Folders from "./components/Folders/Folders";

function App() {

    const ApiPath = 'https://tools.rom-kibirev.ru/api/';

    const [isLogin, setIsLogin] = useState(false);
    const [serverMessage, setServerMessage] = useState();
    const authToServer = useCallback((formData) => {

        if (!isLogin) axios.post(
            `${ApiPath}auth.php`,
            formData,
            { headers: { 'accept': 'application/json' } }
        ).then(response => {
            if (response.data.status === "error") {
                setServerMessage(response.data.message);
            } else {
                setServerMessage(null);

                reactLocalStorage.setObject('user', {
                    "id": response.data.id,
                    "name": response.data.name,
                    "login": formData.get('login'),
                    "password": formData.get('password')
                });

                setIsLogin(true);
            }
        }).catch(error => {
            console.error('error', error);
        });
    }, [ApiPath, isLogin]);
    const userStorage = reactLocalStorage.getObject('user');
    useEffect(() => {

        if (Object.keys(userStorage).length > 0 && !isLogin) {

            const formData = new FormData();
            formData.append('login',userStorage.login);
            formData.append('password',userStorage.password);
            authToServer(formData);
        }
    },[authToServer, isLogin, userStorage]);
    const logoutHandler = () => {
        reactLocalStorage.remove('user');
        setIsLogin(false);
    }

    const [requests, setRequests] = useState([]);
    const [minusRequest, setMinusRequest] = useState({});
    const deleteMinusRequestHandler = (request) => {
        const updateMinusRequests = {};
        const updateRequest = [...requests];

        Object.keys(minusRequest).forEach(id => {
            if (!minusRequest[id].includes(request)) {
                updateMinusRequests[id] = minusRequest[id];
            } else {
                updateRequest.forEach(r => {
                    if (r.id === +id && r.status === "minus") {
                        r.status = "print";
                    }
                });
            }
        });

        setRequests(updateRequest);
        setMinusRequest(updateMinusRequests);
    }
    const updateIgnoreRequestHandler = (id) => {
        if (id !== 'all') setRequests(requests.map(r => r.id === id ? { ...r, status: "print" } : r));
        else setRequests(requests.map(r => r.status === 'ignore' ? { ...r, status: "print" } : r));
    }

    const [filesList,setFilesList] = useState();
    useEffect(() => {

        if (isLogin) axios.post(
            `${ApiPath}userFiles.php`,
            { user: userStorage.id },
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded', // Используем правильный Content-Type
                    'Accept': 'application/json'
                }
            })
            .then(response => {
                setFilesList(response.data);
            })
            .catch(error => {
                console.error('error', error);
            });
    }, [userStorage.id,isLogin]);


    const getDataHandler = async (name) => {
        try {
            const response = await axios.post(
                `${ApiPath}getFile.php`,
                {
                    user: userStorage.id,
                    name: name
                },
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept': 'application/json'
                    }
                }
            );

            setRequests([...response.data.requests.filter(r => r)].sort((a, b) => b.baseFrequency - a.baseFrequency));
            setMinusRequest(response.data.minusRequest);

            // if (!update) {
            //     setRequests(response.data.requests.sort((a, b) => b.baseFrequency - a.baseFrequency));
            //     setMinusRequest(response.data.minusRequest);
            // } else {
            //     return response.data;
            // }
        } catch (error) {
            console.error('error', error);
        }
    };
    const saveDataHandler = async (data, name, minus) => {

        const send = {
            "requests": data,
            "minusRequest": minus ? minus : minusRequest
        }

        console.log(`\n send`, send);

        const sendData = new URLSearchParams();
        sendData.append('data', JSON.stringify(send));
        sendData.append('file', `${name}.json`);
        sendData.append('user', userStorage.id);

        axios.post(
            `${ApiPath}save.php`,
            sendData,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded', // Используем правильный Content-Type
                    'Accept': 'application/json'
                }
            })
            .then(response => {
                if (response.data.success) {
                    console.log(response.data.success);

                    axios.post(
                        `${ApiPath}userFiles.php`,
                        { user: userStorage.id },
                        {
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded', // Используем правильный Content-Type
                                'Accept': 'application/json'
                            }
                        })
                        .then(response => {
                            console.log(response.data);
                            setFilesList(response.data);

                            getDataHandler(name);

                            // window.location.reload();
                        })
                        .catch(error => {
                            console.error('error', error);
                        });
                }
            })
            .catch(error => {
                console.error('error', error);
            });
    }

    const resetDataHandler = () => {
        if (requests.length > 0) {
            setRequests(requests.map(r => ({...r, status: "print" })));
            setMinusRequest({});
        }
    }

    return (
        <BrowserRouter>
            <Suspense>
                <section className={`flex h-screen text-sm`}>
                    <Routes>
                        <Route path="/" element={
                            <UserTools
                                isLogin={isLogin}
                                serverMessage={serverMessage}
                                authToServer={authToServer}
                                userStorage={userStorage}
                                logoutHandler={logoutHandler}
                                saveData={saveDataHandler}
                                filesList={filesList}
                                getDataHandler={getDataHandler}
                            />
                        } />
                        <Route path="/sort/" element={
                            <SortRequests
                                requests={requests}
                                setRequests={setRequests}
                                minusRequest={minusRequest}
                                setMinusRequest={setMinusRequest}
                                deleteMinusRequestHandler={deleteMinusRequestHandler}
                                updateIgnoreRequestHandler={updateIgnoreRequestHandler}
                                getDataHandler={getDataHandler}
                                saveDataHandler={saveDataHandler}
                                resetDataHandler={resetDataHandler}
                            />
                        } />
                        <Route path="/group/" element={
                            <GroupRequests
                                requests={requests}
                                setRequests={setRequests}
                                getDataHandler={getDataHandler}
                                saveDataHandler={saveDataHandler}
                                minusRequest={minusRequest}
                            />
                        } />
                        <Route path="/folders/" element={
                            <Folders ApiPath={ApiPath} />
                        } />
                    </Routes>
                </section>
            </Suspense>
        </BrowserRouter>
    );
}

export default App;
