import styles from './btn.module.css';
import {ReactComponent as Delete} from "../UI/Icons/delete.svg";
import {ReactComponent as Cancel} from "../UI/Icons/cancel.svg";
import {ReactComponent as NewGroup} from "../UI/Icons/newGroup.svg";
import {ReactComponent as ToGroup} from "../UI/Icons/toGroup.svg";
import {ReactComponent as Add} from "../UI/Icons/add.svg";
import {ReactComponent as Up} from "../UI/Icons/up.svg";
import {ReactComponent as Apply} from "../UI/Icons/apply.svg";
import {ReactComponent as Arrow} from "../UI/Icons/arrow.svg";
import {ReactComponent as Refresh} from "../UI/Icons/refresh.svg";
import {ReactComponent as Ignore} from "../UI/Icons/ignore.svg";
import {ReactComponent as View} from "../UI/Icons/view.svg";
import {ReactComponent as Xls} from "../UI/Icons/xlsx.svg";

const Btn = (props) => {

    return (
        <div className={props.center ? 'mx-auto w-max' : ''}>
            <button
                className={`
                ${styles.btn}
                ${props.selected ? styles.selected : ''}
                ${props.method === 'delete' ? styles.delete : ''}
                ${props.method === 'upWord' ? styles.upWord : ''}
                ${(props.method === 'group'|| props.method === 'addGroup') ? styles.group : ''}
                ${props.method === 'groupAlone' ? styles['group-alone'] : ''}
                ${props.method === 'add' ? styles.add : ''}
                ${(props.icon || props.method === 'cancel') ? styles.icon : ''}
                ${props.type === "submit" ? styles.submit : ''}
                ${props.iconOnly ? styles.iconOnly : ''}
                ${props.correct ? styles.correct : ''}
                ${props.method === 'chart' ? styles.chart : ''}
                ${props.method === 'apply' ? styles.apply : ''}
                ${props.method === 'refresh' ? styles.refresh : ''}
                ${props.method === 'ignore' ? styles.ignore : ''}
                ${props.method === 'arrow' ? styles.arrow : ''}
                ${props.method === 'view' ? styles.view : ''}
                ${props.direction === 'right' ? styles.arrowRight : ''}
                ${props.active ? styles.active : ''}
                ${props.className ? props.className : ''}
            `}
                onClick={props.onClick}
                type={props.type}
                title={(props.method && !props.title) === 'delete' ? 'Исключить из списка' : props.title ? props.title : ''}
            >
                {props.iconOnly && props.iconOnly}
                {props.method === 'delete' && <Delete />}
                {props.method === 'upWord' && <Up />}
                {props.method === 'add' && <Add />}
                {props.method === "cancel" && <Cancel />}
                {props.method === "group" && <NewGroup />}
                {props.method === "addGroup" && <ToGroup />}
                {props.method === "groupAlone" && <ToGroup />}
                {props.method === "apply" && <Apply />}
                {props.method === "arrow" && <Arrow />}
                {props.method === "refresh" && <Refresh />}
                {props.method === "ignore" && <Ignore />}
                {props.method === "view" && <View />}
                {props.method === "xls" && <Xls />}
                {props.icon ? props.icon : ''}
                {props.children}
            </button>
        </div>
    );
}

export default Btn;