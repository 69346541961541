import React, {useEffect, useState} from "react";
import Btn from "../Btn";

const PageCount = ({data,step,selectCount,setSelectCount}) => {


    const itemCount = step || 100;
    const [pageBtn, setPageBtn] = useState([]);

    useEffect(() => {
        if (!selectCount) setSelectCount(100);

        const totalPages = Math.ceil(data.length / itemCount);
        const btn = [];

        for (let i = 1; i <= totalPages; i++) {
            const count = i * itemCount;
            btn.push(
                <Btn
                    key={i}
                    selected={selectCount === count}
                    onClick={() => setSelectCount(count)}
                >
                    {count}
                </Btn>
            );
        }

        setPageBtn(btn);
    }, [data.length, setSelectCount, selectCount, itemCount]);

    if (data.length > itemCount) return <div className={`flex flex-wrap px-2 h-max`}>{pageBtn}</div>
}

export default PageCount;