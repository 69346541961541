import React, { useEffect, useState } from "react";
import Btn from "../UI/Btn";

const PrintCharts = (props) => {

    const [charts, setCharts] = useState(null);
    const [selectedChart, setSelectedChart] = useState('0');

    useEffect(() => {
        if (props.requests) {
            const wordsMap = {};

            props.requests
                .filter((p) => p.status === 'print')
                .forEach((r) => {
                    const words = r.request.split(' ');
                    words.forEach((word) => {
                        const cleanedWord = word.trim();
                        if (cleanedWord.length > 2) {
                            const firstLetter = cleanedWord.charAt(0).toLowerCase();
                            if (!wordsMap[firstLetter]) {
                                wordsMap[firstLetter] = [];
                            }
                            if (!wordsMap[firstLetter].includes(cleanedWord)) {
                                wordsMap[firstLetter].push(cleanedWord);
                            }
                        }
                    });
                });

            const chartList = Object.keys(wordsMap).map((chart) => ({
                chart,
                words: wordsMap[chart].sort(),
            })).sort((a, b) => a.chart.localeCompare(b.chart));

            setCharts(chartList);
        }
    }, [props.requests]);

    const handleMouseUp = () => {
        const selectedText = window.getSelection()?.toString()?.trim() || '';
        const selectedWords = [];

        if (selectedText) {

            console.log(`\n selectedText`, selectedText);

            charts[selectedChart].words.forEach((word) => {
                if (selectedText.includes(word) || word.match(new RegExp(`(${selectedText})`, 'gi'))) {
                    selectedWords.push(word);
                }
            });
        }

        if (selectedWords.length > 0) {
            props.setSelectedWords(selectedWords);
        }
    };

    const filteredWords = (charts && charts[selectedChart]) && charts[selectedChart].words.filter((word) => !props.selectedWordListWithCount.some((item) => item.word === word));

    return (
        charts && (
            <div className={`grow flex`}>
                <div className={`w-1/2 px-2 flex flex-wrap h-max`}>
                    {charts.map(({ chart, words }, index) => (
                        <Btn
                            key={index}
                            selected={selectedChart === index.toString()}
                            method="chart"
                            onClick={() => setSelectedChart(index.toString())}
                        >
                            {chart}
                            <span>{words.length}</span>
                        </Btn>
                    ))}
                </div>
                <div className={`h-full w-1/2 overflow-y-auto pt-2`} onMouseUp={handleMouseUp}>
                    {filteredWords && filteredWords.map((word) => (
                        <div className={`border-b border-zinc-300 border-dashed px-2`} key={word}>
                            {word}
                        </div>
                    ))}
                </div>
            </div>
        )
    );
};

export default PrintCharts;