import React, {useEffect, useState} from "react";
import ReactDom from "react-dom";
import styles from "./popup.module.css";
import { ReactComponent as CloseModal } from "./Icons/close.svg";
import Btn from "./Btn";
import layers from "../modules/layers.module.css";
import tables from "./Tables/table.module.css";
import Grids from "./Grid";
import inputs from "./input.module.css";

const Backdrop = (props) => {
    return <div className={styles.backdrop} onClick={props.onCloseModal}></div>;
};

const Popup = (props) => {

    // edit minus
    const [minusWords, setMinusWords] = useState({});
    const [selectedWord, setSelectedWord] = useState();
    useEffect(() => {
        const updateMinusWords = props.minus
            ? Object.keys(props.minus).reduce((acc, id) => {
                props.minus[id].forEach(word => {
                    acc[word] = [...new Set([...(acc[word] || []), +id])];
                });
                return acc;
            }, {})
            : {};

        setMinusWords(updateMinusWords);

        setSelectedWord(Object.keys(updateMinusWords).sort()[0]);
    }, [props.minus]);
    const deleteMinusWordHandler = (word) => {
        if (selectedWord) {
            props.deleteMinusRequestHandler(word);

            const updateMinusWords = {};

            Object.keys(minusWords).forEach(w => {

                if (w !== word) updateMinusWords[w] = minusWords[w];
            });

            setMinusWords(updateMinusWords);

            props.onCloseModal();
        }
    };

    // edit add
    const [addWords, setAddWords] = useState([]);
    useEffect(() => {
        if (props.addMasks) setAddWords([...props.addMasks]);
    },[props.addMasks])
    const deleteAddMasksHandler = (word) => {

        const updateAddMasks = [...addWords];

        props.setAddMasks(updateAddMasks.filter(w => w !== word));
        setAddWords(updateAddMasks.filter(w => w !== word));
    };
    const addAddMasksHandler = (e) => {
        e.preventDefault();
        const addKeysTextarea = document.querySelector('[name="addKeys"]');
        if (addKeysTextarea) {
            const userMasks = addKeysTextarea.value.split(/[., !?;:-]+/).filter(w => w.length > 2);
            const updateAddWords = [...new Set([...addWords, ...userMasks])];
            setAddWords(updateAddWords);
            props.setAddMasks(updateAddWords);
            addKeysTextarea.value = '';
        }
    }
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const addKeysTextarea = document.querySelector('[name="addKeys"]');
            if (addKeysTextarea) {
                addKeysTextarea.blur();
                addAddMasksHandler(e);
            }
        }
    }

    // ignored
    const [ignoredRequests,setIgnoredRequests] = useState([]);
    useEffect(() => {

        setIgnoredRequests(props.ignoredRequests ? props.ignoredRequests : [])
    }, [props.ignoredRequests]);

    // group
    const [groupName, setGroupName] = useState('');

    const handleInputChange = (e) => {
        setGroupName(e.target.value);
    };

    if (props.groups) console.log(`\n props.groups`, props.groups);

    return (
        <React.Fragment>
            {ReactDom.createPortal(
                <Backdrop onCloseModal={props.onCloseModal} />,
                document.getElementById("backdrop")
            )}
            {ReactDom.createPortal(
                <div className={styles.modal}>
                    <header className={styles.header}>
                        <h2>{props.title}</h2>
                        <Btn iconOnly={<CloseModal />} onClick={props.onCloseModal} />
                    </header>
                    <div className={styles.content}>
                        {(props.minus && Object.keys(minusWords).length >0) && <React.Fragment>
                            <div className={`flex flex-row flex-wrap`}>
                                {Object.keys(minusWords).sort().map(word => <Btn
                                    method="view"
                                    selected={word === selectedWord}
                                    key={word}
                                    onClick={() => setSelectedWord(word)}
                                >
                                    {word} - (<b>{minusWords[word].length}</b>)
                                </Btn>)}
                            </div>
                            {(props.requests && selectedWord) && <div className={`mt-2 p-3 border border-mainText/20 rounded-md`}>
                                <Btn
                                    onClick={() => deleteMinusWordHandler(selectedWord)}
                                >
                                    <span className={`pr-3`}>Вернуть исключенные запросы</span>
                                </Btn>
                                {minusWords[selectedWord] && props.requests.filter(r => minusWords[selectedWord].includes(r.id)).map(r => <div key={r.id}>{r.request}</div>)}
                            </div>}
                        </React.Fragment>}
                        {props.modeAddMasks && <React.Fragment>
                            <div className={`w-full flex flex-wrap max-h-70 overflow-y-auto`}>
                                {addWords.map(word => <Btn
                                        method="cancel"
                                        key={word}
                                        onClick={() => deleteAddMasksHandler(word)}
                                    >
                                        {word}
                                    </Btn>)}
                            </div>
                        </React.Fragment>}
                        {props.modeAddMasks && <form onSubmit={addAddMasksHandler} className={`flex w-96 mx-auto mt-3`}>
                                <textarea name={`addKeys`} className={layers.textarea} onKeyPress={handleKeyPress} />
                                <Btn method="apply" type={`submit`} />
                            </form>}
                        {ignoredRequests.length > 0 && <React.Fragment>
                            <Btn
                                onClick={() => {
                                    props.updateIgnoreRequestHandler('all')
                                    props.onCloseModal();
                                }}
                            >Перестать игнорировать весь список запросов</Btn>
                            <div className={`${tables.scroll}`}>
                                <table className={tables.table}>
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th>БЧ</th>
                                        <th>Запрос</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {ignoredRequests.map(r => <tr key={r.id}>
                                        <td>
                                            <Btn
                                                method="refresh"
                                                onClick={() => {
                                                    props.updateIgnoreRequestHandler(r.id)
                                                    props.onCloseModal();
                                                }}
                                            />
                                        </td>
                                        <td>{r.baseFrequency}</td>
                                        <td>{r.request}</td>
                                    </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </React.Fragment>}
                        {props.groupName && <React.Fragment>
                            <div className={layers.additional}>
                                <div className={layers.listArea}>
                                    {props.groupName.matchWords.map(w => <Btn
                                        key={w}
                                        method={'add'}
                                        onClick={() => setGroupName(`${groupName} ${w}`)}
                                        className={`bg-lime-700 hover:bg-lime-700/70`}
                                    >{w}</Btn>)}
                                    {props.groupName.missMatchWords.map(w => <Btn
                                        key={w}
                                        method={'add'}
                                        onClick={() => setGroupName(`${groupName} ${w}`)}
                                    >{w}</Btn>)}
                                </div>
                            </div>
                            <form onSubmit={props.onSubmit}>
                                <Grids cols={2}>
                                    <input
                                        className={inputs.input}
                                        type="text"
                                        name="name"
                                        value={groupName}
                                        onChange={handleInputChange}
                                    />
                                    {groupName && groupName.trim() !== '' && (
                                        <Btn className={`w-full`}>Добавить</Btn>
                                    )}
                                </Grids>
                            </form>
                        </React.Fragment>}
                        {props.groups && <React.Fragment>
                            {Object.keys(props.groups).sort().map((n,id) => {

                                // console.log(`\n n`, n);
                                // console.log(`\n props.groups[n`, props.groups[n]);

                                return (<Btn
                                    key={id}
                                    method="addGroup"
                                    onClick={() => props.onSubmit(n)}
                                >
                                    <span className={`text-sm`}>Добавить в группу&nbsp;</span>
                                    <b><span className={`text-xl`}>"{n}"</span> - ({props.groups[n].length})</b>
                                </Btn>)
                            })}
                        </React.Fragment>}
                        {props.content}
                    </div>
                </div>,
                document.getElementById("modal")
            )}
        </React.Fragment>
    );
};

export default Popup;
