import React, {useEffect, useState} from 'react';
import styles from "./layers.module.css";
import Grids from "../UI/Grid";
import Btn from "../UI/Btn";
import Popup from "../UI/Popup";
import {Link} from "react-router-dom";
import {ReactComponent as Home} from "../UI/Icons/home.svg";
import { utils, writeFile } from 'xlsx';

const LayerTools = (props) => {

    const [baseFrequency, setBaseFrequency] = useState(0);

    const addWordsHandler = (e) => {
        e.preventDefault();
        const addWords = document.querySelector('[name="add-keys"]').value;
        const wordsToAdd = addWords.split(/[,.?! ]+/).map(word => word.trim()).filter(Boolean);
        const newUserMasks = [...new Set([...props.userMasks, ...wordsToAdd])];
        props.setUserMasks(newUserMasks);
        document.querySelector('[name="add-keys"]').value = '';
    }
    const addWordsToGroupHandler = (e) => {
        e.preventDefault();

        const addWords = document.querySelector('[name="add-keys"]').value;
        const wordsToAdd = addWords.split(/[,.?! ]+/).map(word => word.trim()).filter(Boolean);
        document.querySelector('[name="add-keys"]').value = '';

        console.log(`\n `, wordsToAdd);

        if (wordsToAdd.length > 0) wordsToAdd.forEach(word => props.updateSelectedWords([word],'add'));
    }
    const handleKeyPress = (e, type) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.target.blur();
            if (type === 'add') addWordsHandler(e);
            else if (type === 'addogroup') addWordsToGroupHandler(e);
        }
    }
    const updateMinusRequestHandler = () => {

        props.setPopupShow(<Popup
            title={`Редактор исключенных запросов`}
            minus={props.minusRequest}
            requests={props.requests}
            deleteMinusRequestHandler={props.deleteMinusRequestHandler}
            onCloseModal={() => props.setPopupShow(false)}
        />);
    }
    const updateAddMasksHandler = () => {

        props.setPopupShow(<Popup
            title={`Редактор подсвеченных слов`}
            modeAddMasks={true}
            addMasks={props.addMasks}
            setAddMasks={props.setAddMasks}
            onCloseModal={() => props.setPopupShow(false)}
        />);
    }
    const updateIgnoredRequestHandler = () => {

        props.setPopupShow(<Popup
            title={`Редактор проигнорированных запросов`}
            ignoredRequests={props.requests.filter(r => r.status === 'ignore')}
            updateIgnoreRequestHandler={props.updateIgnoreRequestHandler}
            onCloseModal={() => props.setPopupShow(false)}
        />);
    }
    const groupSelectedRequestsHandler = () => {
        const nameWords = props.requests.filter(r => props.selectedGroupRequests.includes(r.id));
        const selectedGroupWords = props.selectedGroupWords;

        const allWords = nameWords
            .map(item => item.request.split(' '))
            .flat()
            .filter((word, index, self) => self.indexOf(word) === index)
            .filter(word => word.length > 2)
            .sort()
        ;

        const matchWords = [];
        const missMatchWords = [];

        allWords.forEach(aw => {
            const matching = selectedGroupWords.some(sw => aw.includes(sw));
            if (matching) {
                matchWords.push(aw);
            } else {
                if (!missMatchWords.includes(aw)) {
                    missMatchWords.push(aw);
                }
            }
        });

        props.setPopupShow(<Popup
            title={`Задайте имя новой группе запросов`}
            groupName={{
                matchWords,
                missMatchWords
            }}
            selectedGroupWords={props.selectedGroupWords}
            onSubmit={props.groupSelectedRequests}
            onCloseModal={() => props.setPopupShow(false)}
            />);
    }
    const addGroupSelectedRequestsHandler = () => {

        const addGroupSelectedRequests = (data) => {

            if (props.selectedGroupRequests.length > 0) {

                // console.log(`\n data`, data);
                // console.log(`\n props.groups[data]`, props.groups[data]);
                // console.log(`\n props.selectedGroupRequests`, props.selectedGroupRequests);
                // console.log(`\n props.requests.find(r => r.id === 20`, props.requests.find(r => r.id === 20));

                const updateRequests = [...props.requests].map(r => {

                    if (props.selectedGroupRequests.includes(r.id)) r['group'] = data;

                    return r;
                });


                props.addGroups(updateRequests);
                // console.log(`\n `, updateRequests.find(r => r.id === 4903));
            }
        }

        props.setPopupShow(<Popup
            title={`Добавить к группе`}
            groups={props.groups}
            onSubmit={addGroupSelectedRequests}
            onCloseModal={() => props.setPopupShow(false)}
        />);

        // const nameWords = props.requests.filter(r => props.selectedGroupRequests.includes(r.id));
        // const selectedGroupWords = props.selectedGroupWords;
        //
        // const allWords = nameWords
        //     .map(item => item.request.split(' '))
        //     .flat()
        //     .filter((word, index, self) => self.indexOf(word) === index)
        //     .filter(word => word.length > 2)
        //     .sort()
        // ;
        //
        // const matchWords = [];
        // const missMatchWords = [];
        //
        // allWords.forEach(aw => {
        //     const matching = selectedGroupWords.some(sw => aw.includes(sw));
        //     if (matching) {
        //         matchWords.push(aw);
        //     } else {
        //         if (!missMatchWords.includes(aw)) {
        //             missMatchWords.push(aw);
        //         }
        //     }
        // });
        //
        // props.setPopupShow(<Popup
        //     title={`Задайте имя новой группе запросов`}
        //     groupName={{
        //         matchWords,
        //         missMatchWords
        //     }}
        //     selectedGroupWords={props.selectedGroupWords}
        //     onSubmit={props.groupSelectedRequests}
        //     onCloseModal={() => props.setPopupShow(false)}
        //     />);
    }

    const generateWorksheet = (data, columnHeaders, sheetName) => {
        // Преобразование данных в нужный формат
        const transformedData = data.map(request => {
            const { id, selected, status, ...rest } = request;

            const renamedRequest = {
                "Запрос": rest.request,
                "Частота": rest.baseFrequency,
                "Сайты": rest.sites.join(', '),
                "Платформы": rest.platform.join(', '),
                "Регионы": rest.region.join(', '),
                "Группа": rest.group,
            };

            return renamedRequest;
        });

        // Преобразование данных в формат листа Excel
        const worksheet = utils.json_to_sheet(transformedData, {
            header: columnHeaders,
        });

        return { worksheet, sheetName };
    };
    const exportXls = () => {
        const wb = utils.book_new();
        const sheetsToAppend = [];

        if (props.requests) {
            const groupedRequests = props.requests
                .filter(r => r.group)
                .sort((a, b) => a.group.localeCompare(b.group));

            if (groupedRequests.length > 0) {
                const { worksheet, sheetName } = generateWorksheet(groupedRequests, [
                    "Запрос",
                    "Частота",
                    "Сайты",
                    "Платформы",
                    "Регионы",
                    "Группа",
                ], "Сгруппированные запросы");
                sheetsToAppend.push({ worksheet, sheetName });
            }

            const addRequests = props.requests
                .filter(r => r.status === 'add' && !r.group);

            if (addRequests.length > 0) {
                const { worksheet, sheetName } = generateWorksheet(addRequests, [
                    "Запрос",
                    "Частота",
                    "Сайты",
                    "Платформы",
                    "Регионы",
                ], "Запросы без групп");
                sheetsToAppend.push({ worksheet, sheetName });
            }

            const unsortedRequests = props.requests
                .filter(r => r.status === 'print');

            if (unsortedRequests.length > 0) {
                const { worksheet, sheetName } = generateWorksheet(unsortedRequests, [
                    "Запрос",
                    "Частота",
                    "Сайты",
                    "Платформы",
                    "Регионы",
                ], "Не обработанные запросы");
                sheetsToAppend.push({ worksheet, sheetName });
            }

            const ignoredRequests = props.requests
                .filter(r => r.status === 'ignore');

            if (ignoredRequests.length > 0) {
                const { worksheet, sheetName } = generateWorksheet(ignoredRequests, [
                    "Запрос",
                    "Частота",
                    "Сайты",
                    "Платформы",
                    "Регионы",
                ], "Проигнорированные запросы");
                sheetsToAppend.push({ worksheet, sheetName });
            }
        }

        const minusWords = [...new Set(Object.values(props.minusRequest).flat())].sort();

        if (minusWords.length > 0) {
            const worksheet = utils.json_to_sheet(minusWords.map(word => ({ "минус-слово": word })), {
                header: ["минус-слово"],
            });
            sheetsToAppend.push({ worksheet, sheetName: "Минус-слова" });
        }

        sheetsToAppend.forEach(sheetData => {
            utils.book_append_sheet(wb, sheetData.worksheet, sheetData.sheetName);
        });

        writeFile(wb, `${props.fileName}.xlsx`);
    };

    useEffect(() => {
        if (props.selectedGroupRequests && props.selectedGroupRequests.length > 0) {
            const check = props.requests.filter(r => props.selectedGroupRequests.includes(r.id));
            const sumOfBaseFrequency = check.reduce((accumulator, currentValue) => accumulator + currentValue.baseFrequency, 0);
            setBaseFrequency(sumOfBaseFrequency);
        } else {
            setBaseFrequency(0);
        }
    }, [props.selectedGroupRequests, props.requests]);

    return (
        <div className={styles.leftBar}>
            <Grids cols={2} className={`place-content-center`}>
                <Link to={`/`} className={`mx-auto w-max`}><Btn iconOnly={true}><Home className={`fill-mainText`} /></Btn></Link>
                {(Array.isArray(props.requests) && props.requests !== undefined && props.requests.length > 0) && <Btn iconOnly={true} method={`xls`} center={true} onClick={exportXls} />}
            </Grids>
            {/* Статистика */}
            <div className={styles.toolsSection}>
                <h2>Инструменты</h2>
                {props.workMethod === 'sort' && <Btn className={`w-full`} onClick={props.resetDataHandler}>Вернуть файл в начало</Btn>}
                {(props.workMethod === 'sort' && props.requests.filter(r => r.status === 'add').length > 0) && <Link to={`/group/?work=${props.fileName}`}>
                    <Btn className={`w-full`}>Группировка запросов</Btn>
                </Link>}
                {(props.workMethod === 'group' && props.requests.length > 0) && <Link to={`/sort/?work=${props.fileName}`}>
                    <Btn className={`w-full`}>Сортировка запросов</Btn>
                </Link>}
            </div>
            {(Array.isArray(props.requests) && props.requests !== undefined && props.requests.length > 0) && <div className={styles.toolsSection}>
                <h2>Статистика по запросам</h2>
                <div>Всего: {props.requests.length}</div>
                {(props.workMethod === 'sort' && props.requests.filter(r => r.status === 'print').length > 0) &&
                    <div>Несортированных: {props.requests.filter(r => r.status === 'print').length}</div>
                }
                {(props.requests.filter(r => r.status === 'add').length > 0) &&
                    <div>В работу: {props.requests.filter(r => (r.status === 'add' && !r.group)).length}</div>
                }
                {(props.selectedRequests && Object.keys(props.selectedRequests).length > 0) &&
                    <div>Выбранных: {Object.keys(props.selectedRequests).length}</div>
                }
                {(props.selectedGroupRequests && props.selectedGroupRequests.length > 0) &&
                    <div>Выбранных: {props.selectedGroupRequests.length}</div>
                }
                {(props.requests.filter(r => r.group).length > 0) && <React.Fragment>
                    <div>Сгруппированных: {props.requests.filter(r => r.group).length}</div>
                    <div>Количество групп: {[...new Set(props.requests.filter(r => r.group).map(r => r.group))].length}</div>
                </React.Fragment>}
            </div>}
            {/* Режим сортировки */}
            {(props.requests.length > 0 && props.workMethod === 'sort') && <div className={styles.toolsSection}>
                <h2>Режим сортировки запросов</h2>
                <Grids cols={2}>
                    <Btn className={`w-full`} onClick={() => props.setMethod(1)}>По запросам</Btn>
                    <Btn className={`w-full`} onClick={() => props.setMethod(2)}>По словам</Btn>
                </Grids>
                {props.requests.filter(r => r.status === 'add').length > 0 && <Btn center={true}>
                    <Link to={`/group/`} className={`w-full`}>Группировка запросов</Link>
                </Btn>}
            </div>}
            {/* Ручной ввод */}
            {(props.requests.length > 0 && props.workMethod === 'sort') && <div className={styles.toolsSection}>
                <h2>Ручной ввод слов</h2>
                {props.userMasks.length > 0 && <div className={styles.additional}>
                    <div className={styles.listArea}>
                        {props.userMasks.map(word => <Btn method="cancel" key={word} onClick={() => props.setUserMasks([...props.userMasks].filter(w => w !== word))}>{word}</Btn>)}
                    </div>
                    <Btn method="arrow" direction={`right`} iconOnly={true} onClick={props.reselectRequests}/>
                </div>}
                <form onSubmit={addWordsHandler} className={`flex`}>
                    <textarea name={`add-keys`} className={styles.textarea} onKeyPress={(e) => handleKeyPress(e,'add')} />
                    <Btn method="apply" type={`submit`} />
                </form>
            </div>}
            {/* Работа с отработанными запросами */}
            {(props.requests.length > 0 && props.workMethod === 'sort') && <div className={styles.toolsSection}>
                <Btn className={`w-full`} onClick={updateAddMasksHandler}>{
                    (props.addMasks && props.addMasks.length > 0) ?
                        `Подсвеченные слова (${props.addMasks.length})`
                        :
                        "Добавить маски"
                }</Btn>
                {(props.minusRequest && Object.keys(props.minusRequest).length > 0) &&
                    <Btn className={`w-full`} onClick={updateMinusRequestHandler}>Исключенные запросы ({Object.keys(props.minusRequest).length})</Btn>
                }
                {(props.requests.filter(r => r.status === 'ignore').length > 0) &&
                    <Btn className={`w-full`} onClick={updateIgnoredRequestHandler}>Проигнорированные запросы ({props.requests.filter(r => r.status === 'ignore').length})</Btn>
                }
            </div>}
            {/* Работа с группировкой */}
            {props.selectedGroupWords && (<div className={styles.toolsSection}>
                <h2>Подобранные слова</h2>
                {props.selectedGroupWords.length > 0 && (<div className={styles.additional}>
                    <div className={styles.listArea}>
                        {props.selectedGroupWords.map((word) => (
                            <Btn
                                method="cancel"
                                key={word}
                                onClick={() => props.updateSelectedWords(word,'exclude')}
                            >{word}</Btn>
                        ))}
                    </div>
                </div>)}
                <form onSubmit={addWordsToGroupHandler} className={`flex`}>
                    <textarea name={`add-keys`} className={styles.textarea} onKeyPress={(e) => handleKeyPress(e,'addogroup')} />
                    <Btn method="apply" type={`submit`} />
                </form>
                {baseFrequency > 0 && <React.Fragment>
                    <div>Базовая частота подобранных запросов {baseFrequency}</div>
                    <Btn method="group" onClick={groupSelectedRequestsHandler}>Создать группу</Btn>
                    {props.groups && <Btn method="addGroup" onClick={addGroupSelectedRequestsHandler}>Добавить в группу</Btn>}
                </React.Fragment>}
            </div>)}
        </div>
    );
}

export default LayerTools;