import React, { useEffect } from 'react';
import axios from 'axios';
import file from "./file.json";


const Folders = (props) => {
    
    const createFolder = async (path) => {

        console.log(`\n `, path);
        try {
            const response = await axios.post(`${props.ApiPath}createFolders.php`, path, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.data.success) {
                console.log('Folders created successfully');
            } else {
                console.error('Failed to create folders');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    
    useEffect(() => {
       Object.keys(file).forEach(id => {

           const c = file[id];
           
           createFolder(c.name);

           Object.keys(c.subcategories).forEach(id2 => {

               const c2 = c.subcategories[id2];

               createFolder(`${c.name}/${c2.name}`);

               Object.keys(c2.subcategories).forEach(id3 => {

                   const c3 = c2.subcategories[id3];

                   createFolder(`${c.name}/${c2.name}/${c3.name}`);

                   Object.keys(c3.subcategories).forEach(id4 => {

                       const c4 = c3.subcategories[id4];

                       createFolder(`${c.name}/${c2.name}/${c3.name}/${c4.name}`);

                       Object.keys(c4.subcategories).forEach(id5 => {

                           const c5 = c4.subcategories[id5];

                           createFolder(`${c.name}/${c2.name}/${c3.name}/${c4.name}/${c5.name}`);

                       });
                   });
               });
           });
       })
    }, []);

    return (
        <div>
            <p>Creating folder structure...</p>
        </div>
    );
};

export default Folders;
