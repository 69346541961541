import React, {useState} from 'react';
import inputs from '../UI/input.module.css';
import { read, utils } from 'xlsx';
import Grid from "../UI/Grid";
import tables from "../UI/Tables/table.module.css";
import Btn from "../UI/Btn";

// import fakeUpload from "./upload.json";

const UploadFile = (props) => {

    const [getFile, setGetFile] = useState([]);
    const getFileHandler = async (event, type) => {
        event.preventDefault();

        const uploadedFile = type === 'input' ? event.target.files[0] : event.dataTransfer.files[0];

        const readData = (data) => {

            const name = uploadedFile.name.split('.').slice(0, -1).join('.').split('_').filter(name => name.length > 2);

            setGetFile({data, name});
        };

        if (uploadedFile) {
            const fileExtension = uploadedFile.name.split('.').pop().toLowerCase();

            let data = [];

            if (fileExtension === 'xls' || fileExtension === 'xlsx') {
                // For XLS/XLSX files, use xlsx library
                const file = await uploadedFile.arrayBuffer();
                const workbook = read(file);
                data = utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {header:1});
                readData(data);
            } else if (fileExtension === 'csv') {
                // For CSV files, use native JavaScript
                const reader = new FileReader();

                reader.onload = (e) => {
                    const text = e.target.result;
                    const lines = text.split('\n');

                    for (let i = 0; i < lines.length; i++) {
                        const row = lines[i].split('\t'); // Use '\t' as the delimiter for CSV
                        data.push(row.map(cell => {

                            return !isNaN(cell.replace(/ /g, '').replace(',', '.')) ? parseFloat(cell.replace(/ /g, '').replace(',', '.')) : cell;
                        }));
                    }

                    readData(data);
                };

                reader.readAsText(uploadedFile);
            } else {
                console.log('Unsupported file format');
            }
        }
    };
    const preventDefault = (event) => {
        event.preventDefault();
    };
    const names = {
        "options": {
            "site": "конкурента",
            "area": "площадку",
            "geo": "регион"
        },
        "requests": {
            "request": "Запрос",
            "frequency": "БЧ"
        }
    }

    const [selectedHeaders, setSelectedHeaders] = useState({});
    const updateSelectedHeaders = (e) => {

        const updatedSelectedHeadCell = { ...selectedHeaders }; // Создайте копию объекта
        updatedSelectedHeadCell[e.target.name] = +e.target.value;
        setSelectedHeaders(updatedSelectedHeadCell);
    }

    const [saveMessage, setSaveMessage] = useState();
    const saveRequestsHandler = async (e) => {
        
        e.preventDefault();

        const requests = getFile.data.map((row,id) => {

            if (id > 0) return ({
                "id": id,
                "request": row[selectedHeaders.request],
                "baseFrequency": row[selectedHeaders.frequency],
                "selected": false,
                "status": "print",
                "sites": [
                    e.target.site.value
                ],
                "platform": [
                    e.target.area.value
                ],
                "region": [
                    e.target.geo.value
                ]
            });
            else return null;
        }).filter(r => r !== null);
        const name = e.target.filename.value;
        const exist = e.target.exist ? e.target.exist.value : '';

        if (name.length === 0 && exist.length === 0) setSaveMessage(`Укажите название файла или укажите к какому файлу добавить`);
        else {

            setSaveMessage(null);

            if (exist) {
                try {
                    const geted = await props.getDataHandler(exist, true);
                    const additionalRequests = {};

                    const getedRequestMap = new Map(geted.requests.map((r) => [r.request, r]));
                    let newId = geted.requests.length + 1;

                    requests.forEach((req) => {
                        if (getedRequestMap.has(req.request)) {
                            // Если запрос уже существует в geted, объединяем данные
                            const existingRequest = getedRequestMap.get(req.request);
                            existingRequest.platform = [...new Set([...existingRequest.platform, ...req.platform])];
                            existingRequest.region = [...new Set([...existingRequest.region, ...req.region])];
                            existingRequest.sites = [...new Set([...existingRequest.sites, ...req.sites])];
                        } else {
                            // Если запрос новый, присваиваем уникальный id
                            req.id = newId++;
                            additionalRequests[req.request] = req;
                        }
                    });
                    geted.requests.forEach((request, index) => {
                        if (getedRequestMap.has(request.request)) {
                            const existingRequest = getedRequestMap.get(request.request);
                            request.id = existingRequest.id;
                        }
                    });

                    const mergedArray = {
                        ...geted,
                        requests: [...geted.requests, ...Object.values(additionalRequests)],
                    };

                    console.log(`\n mergedArray`, mergedArray);
                    console.log(`\n exist`, exist);
                    console.log(`\n geted.minusRequest`, geted.minusRequest);

                    props.saveData(mergedArray.requests, exist, geted.minusRequest);
                } catch (error) {
                    console.error('Error calling getDataHandler:', error);
                }
            }
            else {
                props.saveData(requests,name,null);
            }

            setGetFile([]);
        }
    }
    
    // console.log(`\n `, props.filesList);

    return (
        <div className={`h-max w-max max-w-[70vw] mx-auto my-auto`}>
            <p>Перетащите файл сюда или выберите его</p>
            <div
                onDragOver={preventDefault}
                onDragEnter={preventDefault}
                onDrop={(e) => getFileHandler(e, 'drag')}
                className={`bg-mainText/20 p-20 border border-mainText rounded-md`}
            >
                <input
                    type="file"
                    accept={props.accept}
                    onChange={(e) => getFileHandler(e, 'input')}
                    className={inputs.file}
                />
            </div>
            {(getFile && Object.keys(getFile).length > 0) && <form onSubmit={saveRequestsHandler}>
                <Grid cols={2}>
                    {Object.keys(names.options).map(o => <React.Fragment key={o}>
                        <label>Выберите {names.options[o]}</label>
                        <select className={inputs.input} name={o}>
                            <option className={`hidden`} value="">-- Выберите {names.options[o]} --</option>
                            {getFile.name.map((n => <option key={n}>{n}</option>))}
                        </select>
                    </React.Fragment>)}
                </Grid>
                <div className={`${tables.scroll} mb-2`}>
                    <table className={tables.table}>
                        <thead>
                        <tr>
                            {Object.keys(names.requests).map(o => <th key={o}>
                                <label>Выберите {names.requests[o]}</label>
                                <select
                                    className={`${inputs.input} text-mainText`}
                                    name={o}
                                    onChange={updateSelectedHeaders}
                                >
                                    <option className={`hidden`} value="">-- Выберите {names.requests[o]} --</option>
                                    {getFile.data[0].map(((n,id) => <option key={n} value={id}>{n}</option>))}
                                </select>
                            </th>)}
                        </tr>
                        </thead>
                        <tbody>
                        {Object.keys(selectedHeaders).length === 2 && getFile.data.map((r,id) => {
                            if (id > 0 && id < 5) return (<tr key={id}>
                                <td>{r[selectedHeaders.request]}</td>
                                <td>{r[selectedHeaders.frequency]}</td>
                            </tr>)
                            else return null;
                        })}
                        </tbody>
                    </table>
                </div>
                {Object.keys(selectedHeaders).length === 2 && <React.Fragment>
                    {saveMessage && <h2>{saveMessage}</h2>}
                    <Grid cols={(props.filesList && props.filesList.length > 0) ? 3 : `auto`}>
                        <input placeholder={`Укажите имя файла`} name={`filename`} className={inputs.input}/>
                        {(props.filesList && props.filesList.length > 0) && <React.Fragment>
                            <div>или добавьте к существующему файлу</div>
                            <select className={inputs.input} name={`exist`}>
                                <option value="">-- Выберите файл --</option>
                                {props.filesList.map(f => <option key={f.replace('.json','')}>{f.replace('.json','')}</option>)}
                            </select>
                        </React.Fragment>}
                    </Grid>
                    <Btn method={`submit`} center={true}>Все верно</Btn>
                </React.Fragment>}
            </form>}
        </div>
    );
};

export default UploadFile;
